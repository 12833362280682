<template>
  <div class="homeIndex">
    <div class="body">
      <div>
        <img class="gtbLogin" src="@/assets/logo.png" alt="" />
      </div>
      <div class="btnLogin" @click="link">企业用户登录</div>
      <div class="body-info flex justContent">
        <div class="info-content">
          <p class="en-content">SOMETHING WONDERFUL IS COMING</p> 
          <p class="zh-content">敬请期待</p>
        </div>
      </div>
       <div class="icp-info">
         <div class="info-btn" @click="linkto">
           沪ICP备19025425号-2
         </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
      link(){
          window.open('http://cspp.freetopia.com/login')
      },
      linkto(){
           window.open("https://beian.miit.gov.cn");
      }
  },
};
</script>

<style lang="less" scoped>
.homeIndex {
  width: 100%;

  .body {
    width: 100%;
    min-width: 423px;
    height: 100%;
    background: url("../../assets/bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .gtbLogin {
      position: absolute;
      top: 32px;
      left: 37px;
    }
    .btnLogin {
      position: absolute;
      top: 32px;
      right: 37px;
      border: 1px solid #ffffff;
      padding: 15px 25px;
      color: #ffffff;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      cursor: pointer;
      border-radius: 2px;
    }
  }
}
.flex {
  display: flex;
}
.justContent {
  align-items: center;
  justify-content: center;
}
.body-info {
  width: 100%;
  height: 100%;
  .info-content {
    text-align: center;
    margin-top: -8%;
    .en-content {
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 56px;
      margin-bottom: 0;
    }
    .zh-content {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-top: 20px;
    }
  }
}
.icp-info {
  position: absolute;
  bottom: 16px;
  width: 100%;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  .info-btn {
    cursor: pointer;
  }
}
</style>
